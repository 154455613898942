import { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, Dialog, DialogActions,
  DialogContent, DialogContentText, DialogTitle, Button,
  Typography, Container
} from '@mui/material';
import moment from 'moment';
import axios from '../api/axios';
import utils from '../api/utils.ts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const GET_URL = SERVER_URL + 'api/my_reservations';

const MyBooking = ({ username, todayBookings, selectedOffice = 'Berlin' }) => {
  const [reservationList, setReservationList] = useState([]);
  const [idToDel, setIdToDel] = useState(null);
  const [showAlert, setShowAlert] = useState(null);
  const [processedTodayBookings, setProcessedTodayBookings] = useState([]);

  const getSeatName = (seatId) => {
    const seatMappings = {
      Berlin: {
        16: 'Telefonbox', 
        17: 'Meetingraum (OG)', 
        18: 'Research Office 13', 
        19: 'Research Office 14',
        1: 'Research Office 1', 
        2: 'Research Office 2', 
        3: 'Research Office 3', 
        4: 'Research Office 4', 
        5: 'Research Office 5',
        6: 'Research Office 6', 
        7: 'Research Office 7', 
        8: 'Research Office 8', 
        9: 'Research Office 9', 
        10: 'Research Office 10',
        11: 'Research Office 11', 
        12: 'Research Office 12', 
        13: 'Camp 1', 
        25: 'Camp 2', 
        23: 'Camp 2', 
        27: 'Camp 2',
        24: 'Camp 3', 
        28: 'Camp 3', 
        15: 'Camp 3', 
        20: 'Camp 4', 
        21: 'Camp 4', 
        22: 'Camp 4', 
        26: 'Camp 4',
        29: 'Nische Treppe', 
        30: 'Kitchen Left', 
        31: 'Kitchen Right', 
        32: 'Seminarraum Erdgeschoss'
      },
      Sassenberg: {
        33: 'Garten 1',
        34: 'Garten 2',
        35: 'StraßenBüro 1',
        36: 'Garten 4',
        37: 'Garten 5',
        38: 'Garten 3',
        39: 'StraßenBüro 2',
        40: 'StraßenBüro 3',
        41: 'Finance Meeting Table',
        42: 'Aquarium 1',
        43: 'Finance Desk 3',
        44: 'Finance Desk 4',
        45: 'Finance Desk 2',
        46: 'Aquarium 2',
        47: 'Finance Desk 1',
        48: 'Blaue Sofa'
      }
    };
    return seatMappings[selectedOffice]?.[seatId] || 'Unknown Seat';
  };

  const loadData = async () => {
    try {
      const response = await axios.get(GET_URL, { 
        params: { office: selectedOffice },
        withCredentials: true 
      });
      const rslt = response.data?.rslt || [];

      // Filter reservations for the current user and office
      const userReservations = rslt.filter(val => 
        val.username === username && 
        val.office === selectedOffice
      );

      // Process the reservations
      const reservations = userReservations.map((booking) => {
        const seatName = getSeatName(booking.seatid || booking.seatId);

        let startDate = booking.startDate || booking.startdate;
        let endDate = booking.endDate || booking.enddate;

        startDate = typeof startDate === 'string' ? new Date(startDate) : startDate;
        endDate = typeof endDate === 'string' ? new Date(endDate) : endDate;

        return {
          ...booking,
          seatName,
          startDate,
          endDate,
        };
      });

      setReservationList(reservations);

    } catch (err) {
      console.log("ERROR loadData", err);
    }
  };

  useEffect(() => {
    loadData();
  }, [selectedOffice]); // Reload when office changes

  useEffect(() => {
    // Filter and process today's bookings for current office
    const officeBookings = todayBookings.filter(booking => booking.office === selectedOffice);
    const processed = officeBookings.map((booking) => {
      const seatName = getSeatName(booking.seatid || booking.seatId);
      let startDate = booking.startDate || booking.startdate;
      let endDate = booking.endDate || booking.enddate;

      startDate = typeof startDate === 'string' ? new Date(startDate) : startDate;
      endDate = typeof endDate === 'string' ? new Date(endDate) : endDate;

      return {
        ...booking,
        seatName,
        startDate,
        endDate,
      };
    });
    setProcessedTodayBookings(processed);
  }, [todayBookings, selectedOffice]);

  const handleDelete = (reservationId) => {
    setIdToDel(reservationId);
  };

  const handleClose = () => setIdToDel(null);

  const handleDel = () => {
    const callback = () => {
      refresh();
      setIdToDel(null);
    }
    utils.delReservationDb(idToDel, callback, selectedOffice);  // Pass office to deletion
  }

  const refresh = () => {
    loadData();
    setShowAlert(utils.MSG.del);
    setTimeout(() => { setShowAlert(null); }, 2500);
  }

  return (
    <Container maxWidth="md">
      {/* Success Alert Dialog */}
      <Dialog open={!!showAlert} onClose={() => setShowAlert(null)}>
        <DialogTitle>Success</DialogTitle>
        <DialogContent>
          <DialogContentText>{showAlert}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAlert(null)} color="primary">Close</Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={!!idToDel} onClose={handleClose}>
        <DialogTitle>Delete Reservation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this reservation?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDel} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Today's Bookings Section */}
      <Typography variant="h4" gutterBottom>Today's Bookings - {selectedOffice}</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell>Reason</TableCell>
              <TableCell>Start Time</TableCell>
              <TableCell>End Time</TableCell>
              <TableCell>Seat Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {processedTodayBookings.map((booking, index) => (
              <TableRow key={index}>
                <TableCell>{booking.username}</TableCell>
                <TableCell>{booking.reason || ''}</TableCell>
                <TableCell>{moment(booking.startDate).format('HH:mm')}</TableCell>
                <TableCell>{moment(booking.endDate).format('HH:mm')}</TableCell>
                <TableCell>{booking.seatName}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* My Bookings Section */}
      <Typography variant="h4" gutterBottom>My Bookings - {selectedOffice}</Typography>
      {reservationList.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>Start Time</TableCell>
                <TableCell>End Time</TableCell>
                <TableCell>Seat Name</TableCell>
                <TableCell>Reason</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reservationList.map((booking, index) => (
                <TableRow key={index}>
                  <TableCell>{moment(booking.startDate).format('DD MMM YYYY')}</TableCell>
                  <TableCell>{moment(booking.endDate).format('DD MMM YYYY')}</TableCell>
                  <TableCell>{moment(booking.startDate).format('HH:mm')}</TableCell>
                  <TableCell>{moment(booking.endDate).format('HH:mm')}</TableCell>
                  <TableCell>{booking.seatName}</TableCell>
                  <TableCell>{booking.reason || ''}</TableCell>
                  <TableCell>
                    <Button variant="text" onClick={() => handleDelete(booking.id)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="h6">No reservations until now!</Typography>
      )}
    </Container>
  );
};

export default MyBooking;