import BAlert from 'react-bootstrap/Alert';

export default function Alert(props) {
  // Convert message to string if it's an object
  const message = typeof props.msg === 'object' 
    ? JSON.stringify(props.msg) 
    : props.msg?.toString() || '';

  return(
    <BAlert 
      show={props.show} 
      key={props.variant} 
      variant={props.variant} 
      dismissible='true' 
      onClose={props.setShow}
    >
      {message}
    </BAlert>
  );
}