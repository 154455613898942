import React from 'react';

function FinanceFloorPlan(props) {
  const width = props.width || '100%';
  const height = props.height || '100%';
  
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 450 350"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <filter id="finance_shadow" x="-20%" y="-20%" width="140%" height="140%">
          <feDropShadow dx="2" dy="2" stdDeviation="3" floodOpacity="0.3" />
        </filter>
        <linearGradient id="finance_roomGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#f0e6d2" />
          <stop offset="100%" stopColor="#e6d5b8" />
        </linearGradient>
        <pattern id="finance_floorPattern" patternUnits="userSpaceOnUse" width="60" height="60">
          <rect width="60" height="60" fill="#d7ccc8" />
          <path d="M0 15C10 5,20 0,30 0S50 5,60 15M0 45C10 35,20 30,30 30S50 35,60 45M15 0C5 10,0 20,0 30S5 50,15 60M45 0C35 10,30 20,30 30S35 50,45 60" stroke="#bcaaa4" strokeWidth="0.5" fill="none" />
        </pattern>
        <marker
          id="finance_arrowhead"
          markerWidth="10"
          markerHeight="7"
          refX="9"
          refY="3.5"
          orient="auto"
        >
          <polygon
            points="0 0, 10 3.5, 0 7"
            fill="#b0916e"
          />
        </marker>
      </defs>

      {/* Room Labels */}
      <text x="300" y="40" textAnchor="middle" fill="#5d4037" fontFamily="Arial" fontSize="12">
        Finanzbüro
      </text>
      <text x="100" y="90" textAnchor="middle" fill="#5d4037" fontFamily="Arial" fontSize="12">
        Aquarium
      </text>

      <g transform="translate(200, 50)" filter="url(#finance_shadow)">
        {/* Rest of the SVG content remains the same */}
        <rect
          x="0"
          y="0"
          width="200"
          height="200"
          fill="url(#finance_roomGradient)"
          stroke="#b0916e"
          strokeWidth="1"
        />
        
        <rect
          x="0"
          y="0"
          width="200"
          height="200"
          fill="url(#finance_floorPattern)"
          opacity="0.7"
        />

        <rect
          x="-150"
          y="50"
          width="100"
          height="75"
          fill="url(#finance_roomGradient)"
          stroke="#b0916e"
          strokeWidth="1"
        />
        
        <rect
          x="-150"
          y="50"
          width="100"
          height="100"
          fill="url(#finance_floorPattern)"
          opacity="0.7"
        />

        <rect
          x="74"
          y="74"
          width="52"
          height="52"
          fill="url(#finance_roomGradient)"
          stroke="#b0916e"
          strokeWidth="1"
        />

        <g transform="translate(100, 200)">
          <line
            x1="0"
            y1="0"
            x2="0"
            y2="-30"
            stroke="#b0916e"
            strokeWidth="2"
          />
          <path
            d="M 0,-30 A 30 30 0 0 0 -30 0"
            fill="none"
            stroke="#b0916e"
            strokeWidth="1"
            strokeDasharray="2 2"
          />
          <line
            x1="0"
            y1="20"
            x2="0"
            y2="5"
            stroke="#b0916e"
            strokeWidth="1"
            markerEnd="url(#finance_arrowhead)"
          />
        </g>

        <g transform="translate(0, 0)">
          <rect
            x="0"
            y="5"
            width="60"
            height="20"
            fill="#4a90e2"
            stroke="#2171c7"
            strokeWidth="0.5"
          />
          <rect
            x="0"
            y="0"
            width="60"
            height="8"
            fill="#4a90e2"
            stroke="#2171c7"
            strokeWidth="0.5"
          />
          <path
            d="M2 8 L58 8 M15 8 L15 25 M30 8 L30 25 M45 8 L45 25"
            stroke="#2171c7"
            strokeWidth="0.5"
            fill="none"
          />
          <path
            d="M0 25 L60 25 L60 27 L0 27 Z"
            fill="#2171c7"
          />
        </g>

        <g transform="translate(40, 160)">
          <rect
            x="0"
            y="0"
            width="20"
            height="40"
            fill="#8d6e63"
            stroke="#5d4037"
            strokeWidth="0.5"
          />
          <path
            d="M20 0 L22 0 L22 40 L20 40 Z"
            fill="#5d4037"
          />
        </g>

        <g transform="translate(140, 160)">
          <rect
            x="0"
            y="0"
            width="20"
            height="40"
            fill="#8d6e63"
            stroke="#5d4037"
            strokeWidth="0.5"
          />
          <path
            d="M20 0 L22 0 L22 40 L20 40 Z"
            fill="#5d4037"
          />
        </g>

        <g transform="translate(0, 45)">
          <rect
            x="0"
            y="0"
            width="40"
            height="20"
            fill="#8d6e63"
            stroke="#5d4037"
            strokeWidth="0.5"
          />
          <path
            d="M0 20 L40 20 L40 22 L0 22 Z"
            fill="#5d4037"
          />
        </g>

        <g transform="translate(140, 0)">
          <rect
            x="0"
            y="0"
            width="20"
            height="40"
            fill="#8d6e63"
            stroke="#5d4037"
            strokeWidth="0.5"
          />
          <path
            d="M20 0 L22 0 L22 40 L20 40 Z"
            fill="#5d4037"
          />
        </g>

        <g transform="translate(-150, 80)">
          <rect
            x="0"
            y="0"
            width="20"
            height="40"
            fill="#8d6e63"
            stroke="#5d4037"
            strokeWidth="0.5"
          />
          <path
            d="M20 0 L22 0 L22 40 L20 40 Z"
            fill="#5d4037"
          />
        </g>

        <g transform="translate(-70, 80)">
          <rect
            x="0"
            y="0"
            width="20"
            height="40"
            fill="#8d6e63"
            stroke="#5d4037"
            strokeWidth="0.5"
          />
          <path
            d="M20 0 L22 0 L22 40 L20 40 Z"
            fill="#5d4037"
          />
        </g>
      </g>
    </svg>
  );
}

export default FinanceFloorPlan;