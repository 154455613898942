import React from 'react';

function SVGPlanNewSeminar(props) {
  const width = props.width || '100%';
  const height = props.height || '100%';
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 300 150"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
          <feDropShadow dx="2" dy="2" stdDeviation="3" floodOpacity="0.3" />
        </filter>
        <linearGradient id="roomGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#f0e6d2" />
          <stop offset="100%" stopColor="#e6d5b8" />
        </linearGradient>
        <pattern id="floorPattern" patternUnits="userSpaceOnUse" width="60" height="60">
          <rect width="60" height="60" fill="#d7ccc8" />
          <path d="M0 15C10 5,20 0,30 0S50 5,60 15M0 45C10 35,20 30,30 30S50 35,60 45M15 0C5 10,0 20,0 30S5 50,15 60M45 0C35 10,30 20,30 30S35 50,45 60" stroke="#bcaaa4" strokeWidth="0.5" fill="none" />
        </pattern>
      </defs>
      <g transform="translate(0 -14.7)" filter="url(#shadow)">
        {/* Room outline */}
        <path
          d="M65 16.7 h170 v120 h-170 v-120
          M65 16.7C95 26.7,125 46.7,150 76.7C175 106.7,205 126.7,235 136.7
          M235 136.7C205 126.7,175 106.7,150 76.7C125 46.7,95 26.7,65 16.7"
          fill="url(#roomGradient)"
          stroke="#b0916e"
          strokeWidth="0.5"
        />
        {/* Flooring */}
        <rect
          x="65"
          y="16.7"
          width="170"
          height="120"
          fill="url(#floorPattern)"
          opacity="0.7"
        />
        {/* Art Nouveau border */}
        <path
          d="M65 16.7C95 26.7,125 46.7,150 76.7C175 106.7,205 126.7,235 136.7
          M235 16.7C205 26.7,175 46.7,150 76.7C125 106.7,95 126.7,65 136.7"
          fill="none" stroke="#b0916e" strokeWidth="1"
        />
        {/* Stylized Art Nouveau TV (smaller and higher) */}
        <g transform="translate(145, 25) scale(0.7)">
          <path d="M0 5c5 -5,35 -5,40 0c5 5,5 25,0 30c-5 5,-35 5,-40 0c-5 -5,-5 -25,0 -30z" fill="#2c1e0f" />
          <path d="M3 7c4 -4,30 -4,34 0c4 4,4 22,0 26c-4 4,-30 4,-34 0c-4 -4,-4 -22,0 -26z" fill="#37474f" />
          <path d="M15 35c3 2,7 2,10 0c2 3,2 5,0 7h-10c-2 -2,-2 -4,0 -7" fill="#2c1e0f" />
        </g>
        {/* Ornate doors */}
        <g fill="#b0916e" stroke="#8d6e63" strokeWidth="0.25">
          <path d="M165 134h20c1 0,1 1,1 2v1h-22v-1c0 -1,0 -2,1 -2M165 134c5 -2,15 -2,20 0M165 136c5 -1,15 -1,20 0" />
          <path d="M232 55h3v20h-3zM232 55c0 -1,1 -1,1 -1h1c1 0,1 1,1 1v20c0 1,-1 1,-1 1h-1c-1 0,-1 -1,-1 -1zM232 65h3M232 75h3" />
        </g>
        {/* Art Nouveau style plants */}
        {[[70, 90], [225, 90]].map((pos, i) => (
          <g key={i} transform={`translate(${pos[0]}, ${pos[1]})`}>
            <path d="M10 30c-5 -10,0 -15,5 -20c5 5,10 10,5 20c5 -15,10 -15,15 -20c0 10,-5 15,-10 20c10 -10,15 -5,20 0c-10 0,-15 5,-20 10c10 -5,20 0,25 5c-10 0,-20 0,-25 -5c5 10,0 15,-5 20c-5 -5,-10 -10,-5 -20c-5 15,-10 15,-15 20c0 -10,5 -15,10 -20c-10 10,-15 5,-20 0c10 0,15 -5,20 -10c-10 5,-20 0,-25 -5c10 0,20 0,25 5" fill="#558b2f" />
            <path d="M8 30c1 -10,2 -20,2 -30c1 15,4 15,5 30z" fill="#33691e" />
            <path d="M5 30h10c1 0,2 1,2 2v6c0 1,-1 2,-2 2h-10c-1 0,-2 -1,-2 -2v-6c0 -1,1 -2,2 -2" fill="#4e342e" />
          </g>
        ))}
        {/* Art Nouveau style table (centered) */}
        <g transform="translate(95, 80)">
          <path d="M0 0c20 -5,90 -5,110 0c5 1,5 4,5 5v10c0 1,0 4,-5 5c-20 5,-90 5,-110 0c-5 -1,-5 -4,-5 -5v-10c0 -1,0 -4,5 -5" fill="#8d6e63" stroke="#5d4037" strokeWidth="0.5" />
          <path d="M10 20c-2 2,-2 8,0 10c0 1,-1 2,-2 2h-6c-1 0,-2 -1,-2 -2c2 -2,2 -8,0 -10" fill="#5d4037" />
          <path d="M100 20c2 2,2 8,0 10c0 1,1 2,2 2h6c1 0,2 -1,2 -2c-2 -2,-2 -8,0 -10" fill="#5d4037" />
        </g>
        {/* Art Nouveau style chairs (centered) */}
        {[105, 130, 155, 180].map((x, i) => (
          <g key={i} transform={`translate(${x}, 95)`}>
            <path d="M0 0c0 -5,5 -5,5 -5s5 0,5 5v8c0 5,-5 5,-5 5s-5 0,-5 -5z" fill="#90a4ae" stroke="#607d8b" strokeWidth="0.25" />
            <path d="M2 8c0 8,1 10,3 10s3 -2,3 -10v2h-6z" fill="#607d8b" />
          </g>
        ))}
      </g>
    </svg>
  );
}

export default SVGPlanNewSeminar;