import React, { useContext, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import AuthContext from '../context/AuthProvider';
import styled from 'styled-components';
import { Container, Nav, Navbar, NavDropdown, Tab, Tabs, ButtonGroup, Button } from 'react-bootstrap';
import Diagram from './Diagram';
import ReservationList from './ReservationList';
import MyBooking from './MyBooking';
import ChangePassword from './ChangePassword';
import moment from 'moment';
import axios from '../api/axios';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const GET_URL = SERVER_URL + 'api/my_reservations';

const ElementStyle = styled.div`
  > div {
    width: 1000px;
  }
  .container-fluid {
    // background-color: #e3f2fd;
  }
  .navbar-collapse {
    justify-content: right;
  }
  .show .dropdown-toggle {
    background-color: #0d6efd;
  }
  .office-selector {
    margin: 1rem 0;
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  .active-office {
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
    color: white !important;
  }
`;

function NavBar() {
  const { token, setToken } = useContext(AuthContext);

// At the start of the NavBar component, force Berlin office for spiel role
const [selectedOffice, setSelectedOffice] = useState(() => {
  if (token.role === 'spiel') {
    return 'Berlin';
  }
  return localStorage.getItem('selectedOffice') || 'Berlin';
});

  const [selSeat, setSelSeat] = useState(null);
  const [todayBookings, setTodayBookings] = useState([]);
  const [tomorrowBookings, setTomorrowBookings] = useState([]); // Add this state
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [activeTab, setActiveTab] = useState(
    token.role === 'spiel' ? 'new_seminar' : token.role === 'user' ? 'booking' : 'reservation'
  );

  const officeConfig = {
    Berlin: {
      tabs: [
        { key: 'reservation', title: 'First Floor', svgType: 'main' },
        { key: 'upstairs', title: 'Upstairs', svgType: 'upstairs' },
        { key: 'seminar', title: 'Meeting Raum (OG)/Telefonbox', svgType: 'seminar' },
        { key: 'new_seminar', title: 'Seminarraum Erdgeschoss', svgType: 'new_seminar' }
      ]
    },
    Sassenberg: {
      tabs: [
        { key: 'downstairs', title: 'Garten & StraßenBüro', svgType: 'downstairs' },
        { key: 'finance', title: 'Finanz & Aquarium', svgType: 'finance' }
      ]
    }
  };

  const user = (
    <span>
      {token.user}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <FontAwesomeIcon icon={faUser} transform="grow-5" />
    </span>
  );

  const logout = function () {
    console.log('logout');
    setToken(null);
  };

  const loadData = async () => {
    try {
      const response = await axios.get(GET_URL, { 
        params: { office: selectedOffice },
        withCredentials: true 
      });
      const rslt = (response.data?.rslt || []).map((val) => {
        if (typeof val.startdate === 'string') val.startDate = new Date(val.startdate);
        if (typeof val.enddate === 'string') val.endDate = new Date(val.enddate);
        val.mmtS = moment(val.startDate);
        val.mmtE = moment(val.endDate);
        val.seatName = getSeatName(val.seatid, selectedOffice);
        return val;
      });

      const today = moment().startOf('day');
      const tomorrow = moment().add(1, 'day').startOf('day');

      // Filter today's bookings
      const todayBookings = rslt.filter((val) =>
        moment(val.startDate).isSame(today, 'day') ||
        moment(val.endDate).isSame(today, 'day') ||
        (moment(val.startDate).isBefore(today, 'day') && moment(val.endDate).isAfter(today, 'day'))
      );

      // Filter tomorrow's bookings
      const tomorrowBookings = rslt.filter((val) =>
        moment(val.startDate).isSame(tomorrow, 'day') ||
        moment(val.endDate).isSame(tomorrow, 'day') ||
        (moment(val.startDate).isBefore(tomorrow, 'day') && moment(val.endDate).isAfter(tomorrow, 'day'))
      );

      setTodayBookings(todayBookings);
      setTomorrowBookings(tomorrowBookings);
    } catch (err) {
      console.error('ERROR loadData', err);
    }
  };


  const getSeatName = (seatId, office) => {
    const seatMappings = {
      Berlin: {
        16: 'Telefonbox',
        17: 'Meetingraum (OG)',
        18: 'Research Office 13',
        19: 'Research Office 14',
        1: 'Research Office 1',
        2: 'Research Office 2',
        3: 'Research Office 3',
        4: 'Research Office 4',
        5: 'Research Office 5',
        6: 'Research Office 6',
        7: 'Research Office 7',
        8: 'Research Office 8',
        9: 'Research Office 9',
        10: 'Research Office 10',
        11: 'Research Office 11',
        12: 'Research Office 12',
        13: 'Camp 1',
        25: 'Camp 2',
        23: 'Camp 2',
        27: 'Camp 2',
        24: 'Camp 3',
        28: 'Camp 3',
        15: 'Camp 3',
        20: 'Camp 4',
        21: 'Camp 4',
        22: 'Camp 4',
        26: 'Camp 4',
        29: 'Nische Treppe',
        30: 'Kitchen Left',
        31: 'Kitchen Right',
        32: 'Seminarraum Erdgeschoss'
      },
      Sassenberg: {
        33: 'Garten 1',
        34: 'Garten 2',
        35: 'StraßenBüro 1',
        36: 'Garten 4',
        37: 'Garten 5',
        38: 'Garten 3',
        39: 'StraßenBüro 2',
        40: 'StraßenBüro 3',
        41: 'Finance Meeting Table',
        42: 'Aquarium 1',
        43: 'Finance Desk 3',
        44: 'Finance Desk 4',
        45: 'Finance Desk 2',
        46: 'Aquarium 2',
        47: 'Finance Desk 1',
        48: 'Blaue Sofa'
      }
    };
    
    return seatMappings[office]?.[seatId] || 'Unknown Seat';
  };


  useEffect(() => {
    loadData();
  }, [selectedOffice]); // Reload when office changes

  function onSelectChange(tabElName) {
    setActiveTab(tabElName);
    setSelSeat(null);
  }

  function setSelSeatHandler(id) {
    setSelSeat(id);
  }

  // Reset active tab when switching offices to prevent invalid tab states
  useEffect(() => {
    const defaultTab = token.role === 'user' ? 'booking' : 
                      officeConfig[selectedOffice].tabs[0].key;
    setActiveTab(defaultTab);
  }, [selectedOffice]);

  // Handle office change and persist to localStorage
  const handleOfficeChange = (office) => {
    setSelectedOffice(office);
    localStorage.setItem('selectedOffice', office);
  };

return (
  <ElementStyle>
    <Navbar className="navbar navbar-light">
      <Container fluid>
        <Navbar.Brand href="#home">Book a desk!</Navbar.Brand>
        <Navbar.Collapse id="navbarScroll">
          <Nav>
            <NavDropdown title={user} id="navbarScrollingDropdown" align="end" menuVariant="#e3f2fd">
              <NavDropdown.Item href="#" onClick={() => setShowChangePassword(true)}>
                Change Password
              </NavDropdown.Item>
              <NavDropdown.Item href="#" onClick={logout}>
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

    {token.role !== 'spiel' && (
      <div className="office-selector">
        <ButtonGroup>
          <Button
            variant={selectedOffice === 'Berlin' ? 'primary' : 'outline-primary'}
            onClick={() => handleOfficeChange('Berlin')}
          >
            Berlin Office
          </Button>
          <Button
            variant={selectedOffice === 'Sassenberg' ? 'primary' : 'outline-primary'}
            onClick={() => handleOfficeChange('Sassenberg')}
          >
            Sassenberg Office
          </Button>
        </ButtonGroup>
      </div>
    )}

    <div>
      {showChangePassword ? (
        <ChangePassword onClose={() => setShowChangePassword(false)} />
      ) : (
        <Tabs activeKey={activeTab} onSelect={onSelectChange} className="mb-3">
          {token.role === 'user' && (
            <Tab eventKey="booking" title="Bookings">
              <div className="wrapper-dashboard">
                <MyBooking 
                  username={token.user} 
                  todayBookings={todayBookings}
                  tomorrowBookings={tomorrowBookings} 
                  selectedOffice={selectedOffice} 
                />
              </div>
            </Tab>
          )}

          {officeConfig[selectedOffice].tabs.map(tab => (
            ((token.role === 'spiel' && tab.svgType === 'new_seminar') || 
             (token.role !== 'spiel')) && (
              <Tab eventKey={tab.key} title={tab.title} key={tab.key}>
                <div>
                  <h2>
                    {token.role === 'admin' 
                      ? `ADMIN - Manage ${tab.title}` 
                      : tab.title}
                  </h2>
                  <div className="wrapper-dashboard">
                    <Diagram
                      apiUrl={`${SERVER_URL}api/seats`}
                      setSelSeat={setSelSeatHandler}
                      svgType={tab.svgType}
                      office={selectedOffice}
                      bookings={todayBookings.map((booking) => ({
                        seatId: booking.seatid,
                        date: moment(booking.startDate).format('YYYY-MM-DD'),
                      }))}
                    />
                    <ReservationList 
                      selSeat={selSeat} 
                      selectedOffice={selectedOffice}
                    />
                  </div>
                </div>
              </Tab>
            )
          ))}
        </Tabs>
      )}
    </div>
  </ElementStyle>
);
}

export default NavBar;