import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import Times from './Times';
import 'moment-timezone';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import utils from '../api/utils.ts';
import Alert from './Alert.js';
import CloseButton from 'react-bootstrap/CloseButton';

const ElementStyle = styled.div`
  .wrapper-date-edit {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-top: 20px;
    width: 368px;
    padding: 20px;
    background-color: #fff;
    position: absolute;
    top: 150px;
    right: 20px;
  }

  .disabled-all .react-calendar button {
    pointer-events: none;
  }

  .react-calendar {
    width: 100%;
    border: none;
    font-family: 'Roboto', sans-serif;
  }

  p {
    margin-bottom: 1rem;
    font-size: 1rem;
    color: #333;
  }

  .btn-close {
    float: right;
    cursor: pointer;
  }

  button.btn {
    margin: 3px 0;
    width: 100%;
  }

  .reason-input {
    margin-bottom: 1rem;
    width: 100%;
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 0.9rem;
  }

  .reason-input::placeholder {
    color: #999;
  }
`;

const TIMEZONE = 'Europe/Berlin';

const CalendarContainer = (props) => {
  const selSeat = props.selSeat;
  const wrappeClassName = `wrapper-date-edit ${!props.isCalendarActive ? 'disabled-all' : ''}`;
  
  // Initialize with selected reservation dates if they exist, otherwise use today
  const [dateInterval, setDateInterval] = useState([
    selSeat.startDate ? moment(selSeat.startDate).tz(TIMEZONE).toDate() : moment().tz(TIMEZONE).startOf('day').toDate(),
    selSeat.endDate ? moment(selSeat.endDate).tz(TIMEZONE).toDate() : moment().tz(TIMEZONE).startOf('day').toDate()
  ]);

  const [bookingReason, setBookingReason] = useState(selSeat.reason || '');

  const [timeInterval, setTimeInterval] = useState([
    Math.max(utils.timeToDecimal(moment(selSeat.startDate).tz(TIMEZONE).format('HH:mm')), 8),
    Math.min(utils.timeToDecimal(moment(selSeat.endDate).tz(TIMEZONE).format('HH:mm')), 20)
  ]);
  
  const [showAlert2, setShowAlert2] = useState(false);
  const [msgAlert2, setMsgAlert2] = useState({});

  const setDateOnChange = (evt) => {
    setDateInterval(evt);
  };

  const setTimeOnChange = (evt) => {
    setTimeInterval(evt);
  };

  const handleReasonChange = (evt) => {
    setBookingReason(evt.target.value);
  };

  const check = (mode, dt) => {
      console.log('Check called with mode:', mode, 'dates:', dt);
      let errorMessage = '';
      const currentTime = moment().tz(TIMEZONE);
      const startTime = moment.utc(dt[0]).tz(TIMEZONE);
      
      console.log('Times being checked:', {
          currentTime: currentTime.format('YYYY-MM-DD HH:mm:ss'),
          startTime: startTime.format('YYYY-MM-DD HH:mm:ss'),
          mode: mode
      });

      if (mode === 'edit') {
          const endTime = moment.utc(dt[1]).tz(TIMEZONE);
          if (endTime.isBefore(currentTime)) {
              errorMessage = 'Not possible to modify completely past bookings';
          }
      } else if (mode === 'add') {
          // Instead of checking if it's before current time,
          // check if it's before start of current day
          const startOfToday = moment().tz(TIMEZONE).startOf('day');
          if (startTime.isBefore(startOfToday)) {
              errorMessage = 'Not possible to start a new reservation in the past';
          }
      }
      
      if (!errorMessage) {
          const checkResult = props.check(dt, selSeat.id);
          console.log('Overlap check result:', checkResult);
          if (checkResult) {
              errorMessage = typeof checkResult === 'object' 
                  ? 'Invalid date selection' 
                  : checkResult.toString();
          }
      }
      
      if (errorMessage) {
          console.log('Error found:', errorMessage);
          setShowAlert2(true);
          setMsgAlert2(errorMessage);
          return false;
      }
      return true;
  };


const save = () => {
    const startMoment = moment(dateInterval[0]).tz(TIMEZONE);
    const endMoment = moment(dateInterval[1]).tz(TIMEZONE);

    startMoment.hour(Math.floor(timeInterval[0]))
      .minute((timeInterval[0] - Math.floor(timeInterval[0])) * 60)
      .second(0);
    
    endMoment.hour(Math.floor(timeInterval[1]))
      .minute((timeInterval[1] - Math.floor(timeInterval[1])) * 60)
      .second(0);

    const adjustedStartTime = startMoment.format('YYYY-MM-DD HH:mm:ss');
    const adjustedEndTime = endMoment.format('YYYY-MM-DD HH:mm:ss');

    if (check(selSeat.id ? 'edit' : 'add', [adjustedStartTime, adjustedEndTime])) {
      const params = {
        id: selSeat.id,
        seatId: selSeat.seatId,
        user: props.user,
        interval: [adjustedStartTime, adjustedEndTime],
        reason: bookingReason,
        office: props.selectedOffice // Ensure no fallback to 'Berlin' here
      };

      const callback = (resp) => {
        if (resp && !resp.successful) {
          setShowAlert2(true);
          const errorMessage = resp.rows 
            ? `In the same time ${startMoment.format('YYYY-MM-DD HH:mm')} - ${endMoment.format('YYYY-MM-DD HH:mm')} the user has other reservations (desk ids: ${resp.rows.join(', ')})`
            : 'Error creating reservation';
          setMsgAlert2(errorMessage);
        } else {
          props.refreshFn(selSeat.id ? props.msg.edit : props.msg.add);
        }
      };

      utils.insReservationDb(params, selSeat, callback);
    }
};



  const formatDisplayTime = (date, time) => {
    const displayMoment = moment(date).tz(TIMEZONE)
      .hour(Math.floor(time))
      .minute((time - Math.floor(time)) * 60);
    return displayMoment.format('DD.MM.yyyy HH:mm');
  };

  return (
    <ElementStyle>
      <Alert show={showAlert2} msg={msgAlert2} variant="danger" setShow={setShowAlert2} />
      <div className={wrappeClassName}>
        <CloseButton onClick={() => props.setSelRow(null)} />
        <div className="calendar-container">
          <Calendar
            onChange={setDateOnChange}
            showWeekNumbers={false}
            showNeighboringMonth={true}
            value={dateInterval}
            selectRange={true}
          />
        </div>
        <p>
          <span>from</span> {formatDisplayTime(dateInterval[0], timeInterval[0])}
          <span> to </span> {formatDisplayTime(dateInterval[1], timeInterval[1])}
        </p>
        {props.isCalendarActive && (
          <>
            <div>
              <input 
                type="text"
                className="reason-input"
                value={bookingReason}
                onChange={handleReasonChange}
                placeholder="Enter booking reason (e.g., Yoga, Team Meeting)"
              />
              {dateInterval[0] && <Times timeInterval={timeInterval} setTimeOnChange={setTimeOnChange} />}
            </div>
            <Button type="button" onClick={() => props.setSelRow(null)} variant="secondary">
              Close
            </Button>
            <Button type="button" onClick={save} variant="primary">
              Save <FontAwesomeIcon icon={faSave} />
            </Button>
          </>
        )}
      </div>
    </ElementStyle>
  );
};

export default CalendarContainer;