import React from 'react';

function SVGSassenberg(props) {
  return (
    <div className="w-[800px] h-[500px]">
      <svg
        width="800"
        height="500"
        viewBox="0 0 220 150"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
            <feDropShadow dx="2" dy="2" stdDeviation="3" floodOpacity="0.3" />
          </filter>
          <linearGradient id="roomGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="#f0e6d2" />
            <stop offset="100%" stopColor="#e6d5b8" />
          </linearGradient>
          <pattern id="floorPattern" patternUnits="userSpaceOnUse" width="30" height="30">
            <rect width="30" height="30" fill="#d7ccc8" />
            <path d="M0 7.5C5 2.5,10 0,15 0S25 2.5,30 7.5M0 22.5C5 17.5,10 15,15 15S25 17.5,30 22.5M7.5 0C2.5 5,0 10,0 15S2.5 25,7.5 30M22.5 0C17.5 5,15 10,15 15S17.5 25,22.5 30" stroke="#bcaaa4" strokeWidth="0.25" fill="none" />
          </pattern>
          <marker
            id="arrowhead"
            markerWidth="10"
            markerHeight="7"
            refX="9"
            refY="3.5"
            orient="auto"
          >
            <polygon
              points="0 0, 10 3.5, 0 7"
              fill="#b0916e"
            />
          </marker>
        </defs>

        <text x="45" y="8" textAnchor="middle" fill="#5d4037" fontFamily="Arial" fontSize="6">
          Gartenbüro
        </text>
        <text x="172.5" y="8" textAnchor="middle" fill="#5d4037" fontFamily="Arial" fontSize="6">
          Pasterre
        </text>
        <text x="172.5" y="66" textAnchor="middle" fill="#5d4037" fontFamily="Arial" fontSize="6">
          Straßenbüro
        </text>

        <g transform="translate(12.5, 12.5)" filter="url(#shadow)">
          <rect
            x="0"
            y="0"
            width="75"
            height="125"
            fill="url(#roomGradient)"
            stroke="#b0916e"
            strokeWidth="0.5"
          />
          
          <rect
            x="0"
            y="0"
            width="75"
            height="125"
            fill="url(#floorPattern)"
            opacity="0.7"
          />

          <g transform="translate(75, 15)">
            <line
              x1="0"
              y1="0"
              x2="0"
              y2="15"
              stroke="#b0916e"
              strokeWidth="1"
            />
            <path
              d="M 0,0 A 15 15 0 0 0 -15 15"
              fill="none"
              stroke="#b0916e"
              strokeWidth="0.5"
              strokeDasharray="1 1"
            />
            <line
              x1="20"
              y1="7.5"
              x2="5"
              y2="7.5"
              stroke="#b0916e"
              strokeWidth="0.5"
              markerEnd="url(#arrowhead)"
            />
          </g>

          {[15, 55, 95].map((yPos, index) => (
            <g key={index} transform={`translate(0, ${yPos})`}>
              <rect
                x="0"
                y="0"
                width="10"
                height="20"
                fill="#8d6e63"
                stroke="#5d4037"
                strokeWidth="0.25"
              />
              <path
                d="M10 0 L11 0 L11 20 L10 20 Z"
                fill="#5d4037"
              />
            </g>
          ))}

          {[15, 55].map((yPos, index) => (
            <g key={index} transform={`translate(35, ${yPos})`}>
              <rect
                x="0"
                y="0"
                width="10"
                height="20"
                fill="#8d6e63"
                stroke="#5d4037"
                strokeWidth="0.25"
              />
              <path
                d="M10 0 L11 0 L11 20 L10 20 Z"
                fill="#5d4037"
              />
            </g>
          ))}

          <g transform="translate(135, 12.5)">
            <rect
              x="0"
              y="0"
              width="75"
              height="30"
              fill="url(#roomGradient)"
              stroke="#b0916e"
              strokeWidth="0.5"
            />
            
            <rect
              x="0"
              y="0"
              width="75"
              height="30"
              fill="url(#floorPattern)"
              opacity="0.7"
            />

            <g transform="translate(0, 15)">
              <line
                x1="0"
                y1="0"
                x2="15"
                y2="0"
                stroke="#b0916e"
                strokeWidth="1"
              />
              <path
                d="M 0,-15 A 15 15 0 0 1 15 0"
                fill="none"
                stroke="#b0916e"
                strokeWidth="0.5"
                strokeDasharray="1 1"
              />
              <line
                x1="-20"
                y1="-7.5"
                x2="-5"
                y2="-7.5"
                stroke="#b0916e"
                strokeWidth="0.5"
                markerEnd="url(#arrowhead)"
              />
            </g>

            {/* Moved table left by changing x from 27.5 to 22.5 */}
            <g transform="translate(22.5, 15)">
              <rect
                x="0"
                y="0"
                width="20"
                height="10"
                fill="#8d6e63"
                stroke="#5d4037"
                strokeWidth="0.25"
              />
              <path
                d="M0 10 L20 10 L20 12 L0 12 Z"
                fill="#5d4037"
              />
            </g>
          </g>

          <g transform="translate(135, 62.5)">
            <rect
              x="0"
              y="0"
              width="75"
              height="62.5"
              fill="url(#roomGradient)"
              stroke="#b0916e"
              strokeWidth="0.5"
            />
            
            <rect
              x="0"
              y="0"
              width="75"
              height="62.5"
              fill="url(#floorPattern)"
              opacity="0.7"
            />

            <g transform="translate(0, 15)">
              <line
                x1="0"
                y1="0"
                x2="15"
                y2="0"
                stroke="#b0916e"
                strokeWidth="1"
              />
              <path
                d="M 0,-15 A 15 15 0 0 1 15 0"
                fill="none"
                stroke="#b0916e"
                strokeWidth="0.5"
                strokeDasharray="1 1"
              />
              <line
                x1="-20"
                y1="-7.5"
                x2="-5"
                y2="-7.5"
                stroke="#b0916e"
                strokeWidth="0.5"
                markerEnd="url(#arrowhead)"
              />
            </g>

            <g transform="translate(35, 0)">
              <rect
                x="0"
                y="0"
                width="20"
                height="10"
                fill="#8d6e63"
                stroke="#5d4037"
                strokeWidth="0.25"
              />
              <path
                d="M0 10 L20 10 L20 12 L0 12 Z"
                fill="#5d4037"
              />
            </g>

            <g transform="translate(0, 30)">
              <rect
                x="0"
                y="0"
                width="20"
                height="10"
                fill="#8d6e63"
                stroke="#5d4037"
                strokeWidth="0.25"
              />
              <path
                d="M0 10 L20 10 L20 12 L0 12 Z"
                fill="#5d4037"
              />
            </g>

            <g transform="translate(55, 30)">
              <rect
                x="0"
                y="0"
                width="20"
                height="10"
                fill="#8d6e63"
                stroke="#5d4037"
                strokeWidth="0.25"
              />
              <path
                d="M0 10 L20 10 L20 12 L0 12 Z"
                fill="#5d4037"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

export default SVGSassenberg;