import React, { useState, useEffect, useContext } from 'react';
import Table from 'react-bootstrap/Table';
import Modal from './Modal';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import moment from 'moment';
import utils from '../api/utils.ts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import AuthContext from '../context/AuthProvider';
import CalendarContainer from './CalendarContainer';
import Alert from './Alert';

const ElementStyle = styled.div`
  // your styles here
`;

const currentDate = moment(new Date()).startOf('day').toDate();

const seatMappings = {
  16: 'Telefonbox',
  17: 'Meetingraum (OG)',
  18: 'Research Office 13',
  19: 'Research Office 14',
  1: 'Research Office 1',
  2: 'Research Office 2',
  3: 'Research Office 3',
  4: 'Research Office 4',
  5: 'Research Office 5',
  6: 'Research Office 6',
  7: 'Research Office 7',
  8: 'Research Office 8',
  9: 'Research Office 9',
  10: 'Research Office 10',
  11: 'Research Office 11',
  12: 'Research Office 12',
  13: 'Camp 1',
  25: 'Camp 2',
  23: 'Camp 2',
  27: 'Camp 2',
  24: 'Camp 3',
  28: 'Camp 3',
  15: 'Camp 3',
  20: 'Camp 4',
  21: 'Camp 4',
  22: 'Camp 4',
  26: 'Camp 4',
  29: 'Nische Treppe',
  30: 'Kitchen Left',
  31: 'Kitchen Right',
  32: 'Seminarraum Erdgeschoss',
  33: 'Garten 1',
  34: 'Garten 2',
  35: 'StraßenBüro 1',
  36: 'Garten 4',
  37: 'Garten 5',
  38: 'Garten 3',
  39: 'StraßenBüro 2',
  40: 'StraßenBüro 3',
  41: 'Finance Meeting Table',
  42: 'Aquarium 1',
  43: 'Finance Desk 3',
  44: 'Finance Desk 4',
  45: 'Finance Desk 2',
  46: 'Aquarium 2',
  47: 'Finance Desk 1',
  48: 'Blaue Sofa'
};

function ReservationList(props) {
  const [isCalendarActive, setCalendarActive] = useState(false);
  const [selRow, setSelRow] = useState(null);
  const [childKey, setChildKey] = useState(0);
  const [idToDel, setIdToDel] = useState(null);
  const [showAlert, setShowAlert] = useState(null);
  const [showAlert2, setShowAlert2] = useState(null);
  const { token } = useContext(AuthContext);

  const [reservationData, setReservation] = useState([]);
  
  // Simplified function to get seat name from ID
  const getSeatName = (seatId) => {
    return seatMappings[seatId] || `Desk ${seatId}`;
  };

  useEffect(() => {
    if (props.selSeat) loadData(props.selSeat);
  }, [props.selSeat]);

  function loadData(selSeat) {
    console.log('loadData reservation list', selSeat);
    const params = {
      selSeat: selSeat,
    };

    const callback = function (r) {
      console.log('Received data in callback:', r); // Debug log

      // Add null check before mapping
      if (!r) {
        console.log('No data received');
        setReservation([]);
        return;
      }

      const rslt = r
        .map((val, key) => {
          const reservation = { ...val }; // Create a copy of the reservation

          if (typeof reservation.startdate === 'string') {
            reservation.startDate = new Date(reservation.startdate);
          }
          if (typeof reservation.enddate === 'string') {
            reservation.endDate = new Date(reservation.enddate);
          }
          reservation.name = reservation.seatName;

          return reservation;
        })
        .filter((val) => moment(val.endDate).isSameOrAfter(currentDate, 'day')); // Filter out past reservations

      console.log('Processed reservations:', rslt); // Debug log
      setReservation(rslt);
    };

    utils.getReservationDb(params, callback);
  }

  function onClickRow(_id) {
    setChildKey(_id);
    setCalendarActive(false);
    setSelRow(reservationData.find((item) => item.id === _id));
  }

  function editRow(evt, _id) {
    evt.stopPropagation();
    setChildKey(_id);
    setCalendarActive(true);
    setSelRow(reservationData.find((item) => item.id === _id));
  }

  function delRow(evt, _id, _startDate) {
    evt.stopPropagation();
    if (_startDate < currentDate) {
      setShowAlert2('Not possible to delete a booking which has already started');
    } else {
      setIdToDel(_id);
    }
  }

  function addRow() {
    const tomorrowAfternoon = moment(new Date()).startOf('date').add(42, 'hours').toDate();
    const newRow = {
      id: null,
      seatId: props.selSeat,
      user: token.user,
      startDate: currentDate,
      endDate: tomorrowAfternoon,
    };
    setChildKey(null);
    setCalendarActive(true);
    setSelRow(newRow);
  }

  function checkIfDisabled(item) {
    let isDisabled = false;
    if (item.username !== token.user || item.endDate < currentDate) {
      isDisabled = true;
    }
    return isDisabled;
  }

  const handleClose = () => setIdToDel(false);
  const handleDel = () => {
    const callback = () => {
      refresh(utils.MSG.del);
      setIdToDel(null);
    };

    setChildKey(null);
    setReservation(reservationData.filter((item) => item.id !== idToDel));
    utils.delReservationDb(idToDel, callback);
  };

  function refresh(msg) {
    loadData(props.selSeat);
    setCalendarActive(false);
    setSelRow(null);
    setShowAlert(msg);
    setTimeout(() => {
      setShowAlert(null);
    }, 2500);
  }

  function check(dateInterval, id) {
    const errorData = reservationData.filter(function (item) {
      return (
        item.id !== id &&
        ((dateInterval[0] > item.startDate && dateInterval[0] < item.endDate) || // start inside existing dates
          (dateInterval[1] > item.startDate && dateInterval[1] < item.endDate) || // end inside existing dates
          (dateInterval[0] <= item.startDate && dateInterval[1] >= item.endDate))
      );
    });
    if (errorData.length === 0) return null;
    const htmlData = errorData.map((item, key) => {
      const id = item.id ? '(id ' + item.id + ')' : '';
      const errorMsg1 = `ERROR: not possible to save selected reservation (${dateInterval[0].toLocaleDateString()} - ${dateInterval[1].toLocaleDateString()})`;
      const errorMsg2 = `overlaps the existing one with ${id} (${item.startDate.toLocaleDateString()} - ${item.endDate.toLocaleDateString()})`;
      return (
        <p key={key}>
          {errorMsg1}
          <br />
          {errorMsg2}
        </p>
      );
    });
    return <div>{htmlData}</div>;
  }

  // Add table headers including the Reason column
  const tableHeaders = (
    <thead>
      <tr>
        <th>Seat Name</th>
        <th>Username</th>
        <th>Reservation Period</th>
        <th>Reason</th>
        <th>Edit</th>
        <th>Delete</th>
      </tr>
    </thead>
  );

  const tableContent = reservationData.map((val, key) => {
    const mStartDate = moment(val.startDate);
    const mEndDate = moment(val.endDate);
    const isDisabled = checkIfDisabled(val);
    return (
      <tr
        key={key}
        title="Show reservation"
        onClick={() => onClickRow(val.id)}
        {...((selRow?.id === val.id) ? { className: 'sel-tr' } : {})}
      >
        <td>{val.name}</td>
        <td>{val.username}</td>
        <td>
          <span>from</span>
          <span>
            &nbsp;{mStartDate.format('DD.MM.yyyy')}&nbsp;({mStartDate.format('HH:mm')})
          </span>
          <span>&nbsp;&nbsp;&nbsp;to&nbsp;&nbsp;&nbsp;</span>
          <span>
            &nbsp;{mEndDate.format('DD.MM.yyyy')}&nbsp;({mEndDate.format('HH:mm')})
          </span>
        </td>
        <td>{val.reason || ''}</td> {/* Display the reason */}
        {isDisabled ? (
          <td title="Edit booking not possible">
            <FontAwesomeIcon className="fa-disabled" icon={faPen} />
          </td>
        ) : (
          <td onClick={(evt) => { editRow(evt, val.id); }}>
            <button className="btn" title="Edit booking">
              <FontAwesomeIcon icon={faPen} />
            </button>
          </td>
        )}
        {isDisabled ? (
          <td>
            <FontAwesomeIcon className="fa-disabled" icon={faTrash} />
          </td>
        ) : (
          <td onClick={(evt) => { delRow(evt, val.id, val.startDate); }}>
            <button className="btn" title="Delete booking">
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </td>
        )}
      </tr>
    );
  });

  return (
    <ElementStyle>
      <Alert show={!!showAlert} msg={showAlert} variant="success" setShow={setShowAlert} />
      <Alert show={!!showAlert2} msg={showAlert2} variant="danger" setShow={setShowAlert2} />
      <Modal idToDel={idToDel} handleClose={handleClose} handleDel={handleDel} />
      <div className="div_date_list">
        <p>Reservation for {getSeatName(props.selSeat)}</p>
        <div className="wrapper-scroll">
          {reservationData.length > 0 ? (
            <Table>
              {tableHeaders}
              <tbody>{tableContent}</tbody>
            </Table>
          ) : (
            <>No booking</>
          )}
        </div>
        {props.selSeat && token.role !== 'admin' && (
          <div className="wrapper-plus">
            <Button className="btn" type="button" onClick={addRow}>
              Add <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
        )}
      </div>
      {selRow && (
        <CalendarContainer
          isCalendarActive={isCalendarActive}
          setSelRow={setSelRow}
          selSeat={selRow}
          user={token.user}
          key={childKey}
          refreshFn={refresh}
          msg={utils.MSG}
          check={check}
          currentDate={currentDate}
        />
      )}
    </ElementStyle>
  );
}

export default ReservationList;
